import { Steps } from 'antd';
import moment from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCurrencyPairs from '../../../api/hooks/useCurrencyPairs';
import useCustomerCurrencies from '../../../api/hooks/useCustomerCurrencies';
import useHolidays from '../../../api/hooks/useHolidays';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { getListOfSettlementCurrenciesForPaymentOrders } from '../../../api/NetworkingCurrencies';
import { getValueDate } from '../../../api/NetworkingDeals';
import { getListOfPaymentOrderImportRecords, importPaymertOrdersTemplate } from '../../../api/NetworkingPayments';
import AppContext from '../../../App.context';
import { downloadTemplateCSV, formatDate, formatToCurrency, formatValidInputClass, getTimehhmm, validInputClass } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import UploadModal from '../../beneficiaries/UploadModal';
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import OrderTemplates from '../ordersTemplate/OrderTemplates';
const { Step } = Steps;
interface CurrencyDetailsProps {
  onAddDisbursement: Function;
  useTemplate: Function;
  objOrderScreen?: {} | null;
  fromBalance?: boolean;
}

export interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}

const CurrencyDetails: FunctionComponent<CurrencyDetailsProps> = (props) => {
  const { showModal } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  // Form --------------------------------------------------------------------
  const [customerCurrencies] = useCustomerCurrencies();
  const [lockedSide, setLockedSide] = useState('sell');
  const [buyCurrency, setBuyCurrency] = useState(JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
  const [buyCurrencyItem, setBuyCurrencyItem] = useState();
  const [sellCurrency, setSellCurrency] = useState(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
  const [valueDate, setValueDate] = useState('');
  const [holidays] = useHolidays();
  const [currencyPairs] = useCurrencyPairs();
  const [momentValueDate, setMomentValueDate] = useState(undefined);
  const [momentStartDate, setMomentStartDate] = useState(undefined);
  const [momentEndDate, setMomentEndDate] = useState(undefined);
  const [settlementMethods, setSettlementMethods] = useState([{ label: getResource('SelectSettlementMethod'), value: '' }]);
  const [settlementMethod, setSettlementMethod] = useState({} as any);
  // -------------------------------------------------------------------------
  const [validates, setValidates] = useState(false);
  const contactID = localStorage.getItem('ContactID');
  const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];

  const [templateSelected, setTemplateSelected] = useState<any>();
  // -------------------------------------------------------------------------
  const [paymentOrderImportRecord, setPaymentOrderImportRecord] = useState([] as any);
  const [showUploadBlankTemplate, setShowUploadBlankTemplate] = useState(false);
  const [fileUploaded, setFileUploaded] = useState<any>();
  const [fileUploadAccept, setFileUploadAccept] = useState<string>();
  const onlineInterface = JSON.parse(localStorage.getItem('UserSession')!).OnlineInterface;
  //rights
  let OL_OrderAddNew = useOnlineRights(OnlineRightsNames.OL_OrderAddNew);
  let OL_OrderImport = useOnlineRights(OnlineRightsNames.OL_OrderImport);

  useEffect(() => {
    const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
    getListOfPaymentOrderImportRecords(customerID).then(
      (json) => {
        if (json != null && json.PaymentOrderImportRecord != null) {
          setPaymentOrderImportRecord(json.PaymentOrderImportRecord);
        }
      }
    );
  }, [])

  useEffect(() => {
    if (props.objOrderScreen != null) {
      let obj = props.objOrderScreen as any;
      setBuyCurrency(obj.BuyCurrencyID)
      setSellCurrency(obj.SellCurrencyID)
      setSettlementMethod(obj.SettlementMethod)
    }
  }, [props.objOrderScreen]);

  useEffect(() => {
    getValueDateForCalendar();
    let auxSellCurrency = JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID'];
    getListOfSettlementMethods(auxSellCurrency);
  }, [updating]);

  useEffect(() => {
    if (customerCurrencies !== undefined && (customerCurrencies as ICustomerCurrencies).payment !== undefined) {
      let buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
      setBuyCurrencyItem(buycurrencyItemAux);
    }
  }, [customerCurrencies]);

  useEffect(() => {
    if (buyCurrency !== '' && sellCurrency !== '') {
      getValueDateForCalendar();
    }
  }, [buyCurrency, sellCurrency]);

  useEffect(() => {
    if (templateSelected !== undefined) {
      if (templateSelected.BuyCurrency === buyCurrency && templateSelected.SellCurrency === sellCurrency) {
        props.onAddDisbursement(buyCurrency, buyCurrencyItem, sellCurrency, valueDate, settlementMethod);
        props.useTemplate(templateSelected);
      }
    }
  }, [templateSelected])

  const getListOfSettlementMethods = (currencyID: string) => {
    getListOfSettlementCurrenciesForPaymentOrders(customerID, currencyID).then((jsonResponse: any) => {
      if (jsonResponse.items) {
        const formattedSettlemnts = jsonResponse.items.map((c: any) => {
          const details = c.details.map((d: any) => {
            if (d.label === 'Balance') {
              return { ...d, description: formatToCurrency(d.description, sellCurrency) }
            }
            return d;
          });
          return ({ ...c, label: c.name, details: details });
        });
        formattedSettlemnts.push({ label: getResource('SelectSettlementMethod'), value: '' });
        setSettlementMethods(formattedSettlemnts);
        let indexDefault = -1;
        const defaultSettlementInstrument = JSON.parse(localStorage.getItem('UserSession')!)['DefaultSettlementInstrument'];
        formattedSettlemnts.forEach((element: any, index: number) => {
          if (element.value.includes(defaultSettlementInstrument)) {
            indexDefault = index;
          }
        });
        if (indexDefault >= 0) setSettlementMethod(formattedSettlemnts[indexDefault])
        else setSettlementMethod({ label: getResource('SelectSettlementMethod'), value: '' });
      }

    });
  };

  const calculateEndDate = (startDate: string | Date, buyCurrencyCalculate: string, sellCurrencyCalculate: string) => {
    //Get spot days
    let spotDays = 2;
    //@ts-ignore
    if (currencyPairs[buyCurrencyCalculate + sellCurrencyCalculate] !== undefined) {
      //@ts-ignore
      spotDays = currencyPairs[buyCurrencyCalculate + sellCurrencyCalculate].SpotDays;
    }
    //Recent holidays
    var holidaysHelper = [];
    for (var i = 0; i < holidays.length; i++) {
      //@ts-ignore
      if (
        //@ts-ignore
        holidays[i].CurrencyID === 'ANY' ||
        //@ts-ignore
        holidays[i].CurrencyID === sellCurrency ||
        //@ts-ignore
        holidays[i].CurrencyID === buyCurrencyCalculate
      ) {
        //@ts-ignore
        holidaysHelper.push(holidays[i].Date);
      }
    }

    //Calculate end date
    var endDate = new Date(startDate);
    var daysToAdd = spotDays;

    while (daysToAdd > 0) {
      endDate.setDate(endDate.getDate() + 1);
      if (endDate.getDay() === 6 || endDate.getDay() === 0 || holidaysHelper.includes(formatDate(endDate))) {
        //Nothing at all
      } else {
        daysToAdd--;
      }
    }
    return endDate;
  };

  const getValueDateForCalendar = () => {
    if (sellCurrency !== '' && buyCurrency !== '') {
      let cutoffTime = localStorage.getItem('CutoffTime');

      if (cutoffTime === null) {
        cutoffTime = '12:00';
      }

      let currentDate = new Date();
      let startDate = new Date(localStorage.getItem('SystemDate')!);

      startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);

      let hourStart = getTimehhmm(startDate);
      if (hourStart > cutoffTime) {
        startDate.setDate(startDate.getDate() + 1);
      }

      let endDate = calculateEndDate(startDate, sellCurrency, buyCurrency);

      setMomentStartDate(moment(startDate) as any);
      setMomentEndDate(moment(endDate) as any);

      if (onlineInterface != null && onlineInterface.includes('AFEX - Pass Thru')) {
        getValueDate(customerID, sellCurrency + buyCurrency).then((valueDateResponse: any) => {
          if (valueDateResponse.httpStatusCode !== 200) {
          } else {
            if (valueDateResponse.error != undefined && valueDateResponse.error != '') {
            } else {
              if (valueDateResponse.valueDate != undefined) {
                let rDate = valueDateResponse.valueDate.replace(/['"]+/g, '');
                let date = Date.parse(rDate);
                console.log(date);
                setMomentValueDate(moment(date) as any);
                setValueDate(formatDate(rDate));
              }
            }
          }
        });
      } else {
        setMomentValueDate(moment(startDate) as any);
        setValueDate(formatDate(startDate));
      }
    }
  };

  const disabledDate = (current: any) => {
    return current < momentStartDate! || current > momentEndDate! || moment(current).day() === 0 || moment(current).day() === 6;
  };

  const onChangeValueDate = (date: any, dateString: string) => {
    setValueDate(dateString);
    setMomentValueDate(date);
  };

  const dropdownBuyChangeHandler = (item: any) => {
    if (props.fromBalance != null && props.fromBalance) {
      setBuyCurrency(item.value);
      setBuyCurrencyItem(item);
    } else {
      if (item.value !== sellCurrency) {
        setBuyCurrency(item.value);
        setBuyCurrencyItem(item);
      } else {
        if (item.value !== '') {
          showModal(getResource('Js_MessageError_TitleError'), getResource('Message_Same_Currency_Orders_Not_Available'));
        }
      }
    }
  };
  const dropdownSellChangeHandler = (item: any, name: string) => {
    if (props.fromBalance != null && props.fromBalance) {
      setSellCurrency(item.value);
      getListOfSettlementMethods(item.value);
    } else {
      if (item.value !== buyCurrency) {
        setSellCurrency(item.value);
        getListOfSettlementMethods(item.value);
      } else {
        if (item.value !== '') {
          showModal(getResource('Js_MessageError_TitleError'), getResource('Message_Same_Currency_Orders_Not_Available'));
        }
      }
    }
  };

  const dropdownSettlementsChangeHandler = (item: any, name: string) => {
    setSettlementMethod(item);
    setValidates(false)
    console.log(item);
  };

  //---------------Buttons Blank Templates

  const downloadBlankTemplate = () => {
    downloadTemplateCSV(paymentOrderImportRecord, 'ImportPaymentOrdersTemplate');
  }
  const uploadBlankTemplate = () => {
    setShowUploadBlankTemplate(true);
  }
  const onFileChangeHandler = (event: any) => {
    setFileUploaded(event.target.files[0])
  }
  const importFile = () => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    readFileAsBase64(fileUploaded).then(
      (data: any) => {
        let fileContent = data.replace(/^data:.+;base64,/, '');
        var model = {
          'FileContent': fileContent,
          'UserID': sessionStorage.getItem('UserID'),
          'FileName': fileUploaded.name,
          'CustomerID': userSession.CustomerID
        }
        importPaymertOrdersTemplate(model).then((response: any) => {
          if (response != null) {
            let responseStatus = response.httpStatusCode
            var payments = (response.BeneficiaryPayments != null && response.BeneficiaryPayments.BeneficiaryPayment != null) ? response.BeneficiaryPayments.BeneficiaryPayment : [];
            let outgoingFundsList = [] as any;
            let settlementMethod = {};
            let buycurrencyItemAux = {} as any;
            switch (responseStatus) {
              case 200:
                if (payments.length > 0) {
                  let CurrencyToWork = payments[0].CurrencyID;
                  let BuyCurrency = JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID'];//['DefaultSellCurrencyID'];

                  payments = payments.filter((i: any) => i.CurrencyID === CurrencyToWork);

                  payments.forEach((payment: any, index: number) => {
                    /*
                      Amount: 1000
                      BeneficiaryBankAccountID: 1012328
                      BeneficiaryID: 13928
                      CurrencyID: "AUD"
                      Instrument: "Electronic Funds Transfer"
                      PaymentReference: "My reference"
                      ValueDate: "2021-09-07T00:00:00"
                      accountNo: "7564456456"
                      bankName: "Bank of Montreal "
                      beneficiaryName: "Sammy Hernandez"
                      beneficiaryType: "Personal"
                      id: "13928;1012328"
                      preferredDeliveryMethod: "We Drop-off"
                      status: "Authorized"
                      text: "Sammy Hernandez-Bank of Montreal "
                     */
                    outgoingFundsList.push(
                      {
                        BeneficiaryPaymentID: payment.BeneficiaryID,
                        Instrument: payment.Instrument,
                        BeneficiaryID: payment.BeneficiaryID,
                        BeneficiaryBankAccountID: payment.BeneficiaryBankAccountID,
                        ValueDate: payment.ValueDate,
                        SellAmount: Number(payment.Amount),
                        PaymentReference: payment.PaymentReference,
                        WireItemFee: 0,
                        WireItemFeeCurrencyID: null,
                        InterfaceBalanceAccountId: null,
                        Comments: null,
                        Attachments: [],
                        DeliveryMethod: payment.preferredDeliveryMethod,
                        CurrencyID: null,
                        BeneficiaryName: null,
                        BankName: null,
                        AccountNo: null,
                        BeneficiaryType: payment.beneficiaryType,
                        BeneficiaryStatus: null,
                        BankAccountID: 0,
                        entityProperties: [],
                      }
                    );
                    settlementMethod = {
                      details: [
                        { label: 'Currency', description: BuyCurrency },
                        { label: 'Instrument', description: 'Electronic Funds Transfer' },
                        { label: 'Delivery Method', description: 'ACH' }
                      ],
                      label: `${BuyCurrency} - Electronic Funds Transfer - ${BuyCurrency}`
                    };
                    buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === CurrencyToWork)
                  });
                  setValueDate(valueDate);
                  props.useTemplate({
                    DealTemplateID: 0,
                    CustomerId: userSession.CustomerID,
                    ContactId: userSession.ContactID,
                    TemplateName: 'Regular Payment',
                    SellAmount: Number(payments[0].Amount),
                    SellCurrency: BuyCurrency,
                    BuyCurrency: CurrencyToWork,
                    Disbursements: outgoingFundsList,
                    UpdateToken: '',
                    SendingToBeneficiary: `Sending ${Number(formatToCurrency(payments[0].Amount, BuyCurrency))} to 1 beneficiary(ies)`,
                    PayIn: `Paying in ${formatToCurrency(CurrencyToWork, CurrencyToWork)}`
                  });
                  props.onAddDisbursement(CurrencyToWork, buycurrencyItemAux, BuyCurrency, valueDate, settlementMethod);
                showModal(getResource('Js_Message_SubmitSucessful'), getResource('Js_Message_FileImportedSuccessfully'));
                }
                break;
              case 409:
                showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
                break;
              case 405:
              case 403:
              case 400:
                showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
                break;
              case 506:
              case 503:
              case 500:
                showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
                break;
              default:
                showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
                break;
            }
          }
          //}
        }).finally(() => {
          setFileUploadAccept(undefined);
          setFileUploaded(undefined);
          setShowUploadBlankTemplate(false);
        });
      }
    )
  }


  const readFileAsBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  return (
    <>
      <UploadModal showModalUpload={showUploadBlankTemplate} title={getResource('UploadOrderTemplate')} fileUploaded={fileUploaded} accept={fileUploadAccept} setShowModalUpload={() => {
        setFileUploadAccept(undefined);
        setFileUploaded(undefined);
        setShowUploadBlankTemplate(false);
      }}
        onFileChangeHandler={onFileChangeHandler} importFile={importFile}>
      </UploadModal>
      <div className="screen-container slide-r-l">
        {OL_OrderImport && <div className='row center-xs slide-t-b'>
          <div className='col-md-4'>
            <Button
              type={'secondary'}
              onClick={downloadBlankTemplate}
            >{getResource('SubMenu_DownloadTemplateForOrders')}
            </Button>
          </div>
          <div className='col-md-4'>
            <Button
              type={'secondary'}
              onClick={uploadBlankTemplate}
            >{getResource('UploadOrderTemplate')}
            </Button>
          </div>
        </div>}
        <div className="row center-xs around-xs">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.BuyCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown name="buyCurrency" title={getResource('SelectCurrencyBuy')} list={(customerCurrencies as ICustomerCurrencies).payment} onChange={dropdownBuyChangeHandler} select={{ value: buyCurrency }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SellCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="sellCurrency"
                    title={getResource('SelectCurrencySell')}
                    list={(customerCurrencies as ICustomerCurrencies).settlement}
                    onChange={(item: any, name: string) => { dropdownSellChangeHandler(item, name); }}
                    select={{ value: sellCurrency }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.ValueDate')}</span>
                </div>
                <div className="row">
                  <DatePicker key="app-datepicker-valuedate" onChange={onChangeValueDate} value={momentValueDate} disabledDate={disabledDate} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SettlementMethod')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="settlementMethodOrder"
                    title={getResource('SelectSettlementMethod')}
                    className={validates ? formatValidInputClass(validInputClass(settlementMethod.value)) : ''}
                    settlementMode={true}
                    list={settlementMethods}
                    onChange={dropdownSettlementsChangeHandler}
                    //submit={true}
                    select={{ value: settlementMethod.value }}
                    disabled={sellCurrency === '' ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row center-xs" style={{ paddingTop: 20 }}>
              <div className="col-md-10">
                <Button
                  type={buyCurrency === '' || sellCurrency === '' || valueDate === '' || settlementMethod.value === '' ? 'disable' : 'secondary'}
                  onClick={() => {
                    props.onAddDisbursement(buyCurrency, buyCurrencyItem, sellCurrency, valueDate, settlementMethod);
                  }}
                >{getResource('AddDisbursement')}
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <OrderTemplates
              type='inline'
              onUseNowATemplate={(template: any) => {
                setBuyCurrency(template.BuyCurrency);
                setSellCurrency(template.SellCurrency);
                let buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === template.BuyCurrency);
                setBuyCurrencyItem(buycurrencyItemAux);
                getListOfSettlementMethods(template.SellCurrency);
                setTemplateSelected(template);
              }}
            />
          </div>
        </div>
      </div>
      <div className="disclaimer-container">
        <div className="row center-xs">
          <div className="col-xs-12 row rate-disclaimer">
            <span>{getResource('AFEX_Message')}</span>
          </div>
          {(onlineInterface != null && onlineInterface.includes('AFEX - Pass Thru')) &&
            <span className="afex-disclaimer col-xs-12">
            {getResource('AFEX_Message_TC')}
          </span>
          }
        </div>
      </div>
    </>
  );
};

export default CurrencyDetails;
