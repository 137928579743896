import moment from 'moment';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCurrencyPairs from '../../../api/hooks/useCurrencyPairs';
import useCustomerCurrencies from '../../../api/hooks/useCustomerCurrencies';
import useHolidays from '../../../api/hooks/useHolidays';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { usePrevious } from '../../../api/hooks/usePrevious';
import { getListOfSettlementCurrenciesForPaymentOrders } from '../../../api/NetworkingCurrencies';
import { getValueDate, saveDealHeader, validateCustomerAndContactLimits } from '../../../api/NetworkingDeals';
import AppContext from '../../../App.context';
import { formatDate, formatToCurrency, formatValidInputClass, getTimehhmm, scaleByCurrency, validInputClass } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import RadioButton from '../../inputs/RadioButton';
import { Deal } from '../../models/Deal';
import ConfirmationModal from '../../others/ConfirmationModal';
import QuoteBox from '../../rates/quoteBox/QuoteBox';
import SendApprovals from '../validates/SendApprovals';

const defaultSettlementName = 'Electronic Funds Transfer';
interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}

interface SpotProps {
  onAcceptTrade: Function;
  dealObj: Deal;
  dealHeaderID: number;
  fromOtherScreen: string;
  changeStep: Function;
  objOrderScreen?: {} | null;
}

const Spot: FunctionComponent<SpotProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);

  const [lockedSide, setLockedSide] = useState('sell');
  const [currencyPairs] = useCurrencyPairs();
  const [holidays] = useHolidays();
  const [customerCurrencies] = useCustomerCurrencies();
  const [settlementMethods, setSettlementMethods] = useState([{ label: getResource('SelectSettlementMethod'), value: '' }]);
  const [settlementMethod, setSettlementMethod] = useState({} as any);
  const [buyCurrency, setBuyCurrency] = useState(JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
  const [sellCurrency, setSellCurrency] = useState(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
  const [valueDate, setValueDate] = useState('');
  const [momentValueDate, setMomentValueDate] = useState(undefined);
  const [momentStartDate, setMomentStartDate] = useState(undefined);
  const [momentEndDate, setMomentEndDate] = useState(undefined);
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(0.0);
  const [feeAmount, setFeeAmount] = useState(0.0);
  const [validates, setValidates] = useState(true);
  const [showApprovalsPopUp, setShowApprovalsPopUp] = useState(false);
  const [custApprovals, setApprovals] = useState('');
  const [scaleBuyAmount, setScaleBuyAmount] = useState(2);
  const [scaleSellAmount, setScaleSellAmount] = useState(2);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [dealToConfirmAcceptance, setDealToConfirmAcceptance] = useState({} as any);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');

  const contactID = localStorage.getItem('ContactID');
  const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
  console.log(JSON.parse(localStorage.getItem('UserSession')!));
  const defaultSettlementInstrument = JSON.parse(localStorage.getItem('UserSession')!)['DefaultSettlementInstrument'];
  const onlineInterface = JSON.parse(localStorage.getItem('UserSession')!).OnlineInterface;

  // test
  //otherScreens
  const [comeFromtOherScreen, setComeFromOtherScreen] = useState<string>('');
  const prevComeFromtOherScreen = usePrevious(comeFromtOherScreen);

  //for approvals
  const [customerRateDeal, setCustomerRateDeal] = useState(0.0);
  const [dealID, setDealID] = useState(0);

  //after contact limit validation
  const [objDealHeaderAfterValidation, setObjDealHeaderAfterValidation] = useState({});
  const [showConfirmModalValidation, setShowConfirmModalValidation] = useState<boolean>(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  //rights
  let OL_SpotAddNew = useOnlineRights(OnlineRightsNames.OL_SpotAddNew);
  let OL_SpotGetQuote = useOnlineRights(OnlineRightsNames.OL_SpotGetQuote);

  const calculateEndDate = (startDate: string | Date, buyCurrencyCalculate: string, sellCurrencyCalculate: string) => {
    //Get spot days
    let spotDays = 2;
    //@ts-ignore
    if (currencyPairs[buyCurrencyCalculate + sellCurrencyCalculate] !== undefined) {
      //@ts-ignore
      spotDays = currencyPairs[buyCurrencyCalculate + sellCurrencyCalculate].SpotDays;
    }
    //Recent holidays
    var holidaysHelper = [];
    for (var i = 0; i < holidays.length; i++) {
      //@ts-ignore
      if (
        //@ts-ignore
        holidays[i].CurrencyID === 'ANY' ||
        //@ts-ignore
        holidays[i].CurrencyID === sellCurrency ||
        //@ts-ignore
        holidays[i].CurrencyID === buyCurrencyCalculate
      ) {
        //@ts-ignore
        holidaysHelper.push(holidays[i].Date);
      }
    }

    //Calculate end date
    var endDate = new Date(startDate);
    var daysToAdd = spotDays;

    while (daysToAdd > 0) {
      endDate.setDate(endDate.getDate() + 1);
      if (endDate.getDay() === 6 || endDate.getDay() === 0 || holidaysHelper.includes(formatDate(endDate))) {
        //Nothing at all
      } else {
        daysToAdd--;
      }
    }
    return endDate;
  };

  const getValueDateForCalendar = () => {
    if (sellCurrency !== '' && buyCurrency !== '') {
      let cutoffTime = localStorage.getItem('CutoffTime');

      if (cutoffTime === null) {
        cutoffTime = '12:00';
      }

      let currentDate = new Date();
      let startDate = new Date(localStorage.getItem('SystemDate')!);

      startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);

      if (getTimehhmm(startDate) > cutoffTime) {
        startDate.setDate(startDate.getDate() + 1);
      }

      let endDate = calculateEndDate(startDate, sellCurrency, buyCurrency);

      setMomentStartDate(moment(startDate) as any);
      setMomentEndDate(moment(endDate) as any);

      let afexSession = JSON.parse(localStorage.getItem('UserSession')!);
      if (afexSession.OnlineInterface != null && afexSession.OnlineInterface.includes('AFEX - Pass Thru')) {
        getValueDate(customerID, sellCurrency + buyCurrency).then((valueDateResponse: any) => {
          if (valueDateResponse.httpStatusCode !== 200) {
          } else {
            if (valueDateResponse.error != undefined && valueDateResponse.error != '') {
            } else {
              if (valueDateResponse.valueDate != undefined) {
                let date = Date.parse(valueDateResponse.valueDate);
                console.log(date);
                setMomentValueDate(moment(date) as any);
                setValueDate(formatDate(valueDateResponse.valueDate));
              }
            }
          }
        });
      } else {
        setMomentValueDate(moment(startDate) as any);
        setValueDate(formatDate(startDate));
      }
    }
  };

  useEffect(() => {
    if (props.fromOtherScreen !== '') {
      setComeFromOtherScreen(props.fromOtherScreen);
      if (props.fromOtherScreen === 'balances' && props.dealObj && prevComeFromtOherScreen !== 'balances') {
        let auxBuyCurrency: string = '';
        let auxSellCurrency: string = '';

        if (props.dealObj.BuyCurrency !== '') {
          auxBuyCurrency = props.dealObj.BuyCurrency;
        } else {
          auxBuyCurrency = JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID'];
        }

        if (props.dealObj.SellCurrency !== '') {
          auxSellCurrency = props.dealObj.SellCurrency;
        } else {
          auxSellCurrency = JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID'];
        }

        setBuyCurrency(auxBuyCurrency);
        setSellCurrency(auxSellCurrency);
        getListOfSettlementMethods(auxSellCurrency);

        if (props.dealObj.BuyAmount > 0) {
          setLockedSide('sell');
          setBuyAmount(props.dealObj.BuyAmount);
          setQuoteAmount(parseFloat(props.dealObj.BuyAmount.toString()));
        } else {
          setLockedSide('buy');
          setSellAmount(props.dealObj.SellAmount);
          setQuoteAmount(parseFloat(props.dealObj.SellAmount.toString()));
        }
      } else {
        getValueDateForCalendar();
      }
    } else {
      getValueDateForCalendar();
    }
    if (props.objOrderScreen != null) {
      let obj = props.objOrderScreen as any;
      setBuyCurrency(obj.BuyCurrencyID);
      setSellCurrency(obj.SellCurrencyID);
      setBuyAmount(obj.BuyAmount);
      setSellAmount(obj.SellAmount);
    }
  }, [buyCurrency, sellCurrency, props.fromOtherScreen, props.objOrderScreen]);

  const validateFields = () => {
    //debugger;
    var fields = [
      validInputClass(buyAmount.toString()),
      validInputClass(sellAmount.toString()),
      settlementMethod.value]
    fields.forEach(element => {
      if (!element) {
        setValidates(true)
        return true;
      } else {
        setValidates(false)
        return false;
      }
    })
  }

  const disabledDate = (current: any) => {
    return current < momentStartDate! || current > momentEndDate! || moment(current).day() === 0 || moment(current).day() === 6;
  };

  const radioChangeHandler = (event: any) => {
    setLockedSide(event.target.value);
    if (event.target.value === 'buy') {
      setBuyAmount(0);
      setQuoteAmount(0.0);
    } else {
      setSellAmount(0);
      setQuoteAmount(0.0);
    }
  };

  const onChangeValueDate = (date: any, dateString: string) => {
    setValueDate(dateString);
    setMomentValueDate(date);
  };

  const dropdownSettlementsChangeHandler = (item: any, name: string) => {
    setSettlementMethod(item);
    //setValidates(false)
    console.log(item);
  };

  const dropdownBuyChangeHandler = (item: any, name: string) => {
    setScaleBuyAmount(item.NumberOfDecimals);
    setBuyCurrency(item.value);
  };

  const dropdownSellChangeHandler = (item: any, name: string) => {
    setScaleSellAmount(item.NumberOfDecimals);
    setSellCurrency(item.value);
    getListOfSettlementMethods(item.value);
  };

  const getListOfSettlementMethods = (currencyID: string) => {
    getListOfSettlementCurrenciesForPaymentOrders(customerID, currencyID).then((jsonResponse: any) => {
      if (jsonResponse.items) {
        const formattedSettlemnts = jsonResponse.items.map((c: any) => {
          const details = c.details.map((d: any) => {
            if (d.label === 'Balance') {
              return { ...d, description: formatToCurrency(d.description, sellCurrency) }
            }
            return d;
          });
          return ({ ...c, label: c.name, details: details });
        });
        formattedSettlemnts.push({ label: getResource('SelectSettlementMethod'), value: '' });
        setSettlementMethods(formattedSettlemnts);
        formattedSettlemnts.forEach((element: any) => {
          if (element.value.includes(defaultSettlementInstrument)) {
            setSettlementMethod(element);
          }
        });

      }
    });
  };

  const onChangeBuyAmount = (value: number) => {
    if (lockedSide === 'sell') {
      setBuyAmount(value);
      setValidates(false)
      setQuoteAmount(value);
    }
  };

  const onChangeSellAmount = (value: number) => {
    if (lockedSide === 'buy') {
      setSellAmount(value);
      setValidates(false)
      setQuoteAmount(value);
    }
  };

  const amountCalc = (amount: number) => {
    console.log(amount);
    if (lockedSide === 'buy') {
      setBuyAmount(amount);
    } else {
      setSellAmount(amount);
    }
  };

  const onNewTradeClick = () => {
    setLockedSide('sell');
    setSettlementMethods([{ label: getResource('SelectSettlementMethod'), value: '' }]);
    setBuyCurrency(JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
    setSellCurrency(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
    getListOfSettlementMethods(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
    //setValueDate('');
    setBuyAmount(0);
    setSellAmount(0);
    setQuoteAmount(0.0);
    setSettlementMethod({ label: getResource('SelectSettlementMethod'), value: '' });
    //setMomentValueDate(undefined);
  };

  const onCancelQuoteClick = () => {
    if (lockedSide === 'buy') {
      setBuyAmount(0);
    } else {
      setSellAmount(0);
    }
  };

  const onAcceptClick = async (rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number) => {
    showLoadingModal();
    let fixedLockedSide;
    if (lockedSide === 'buy') {
      fixedLockedSide = 'Buy';
    } else {
      fixedLockedSide = 'Sell';
    }
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let array = settlementMethod.value.split(';');
    let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));

    let objDealHeader = {
      BuyAmount: sellAmount.toFixed(scaleByCurrency(sellCurrency)),
      BuyCurrency: sellCurrency,
      CoverCustomerID: coverCustomerID,
      CreatedBy: localStorage.getItem('UserID'),
      CustomerContactID: localStorage.getItem('ContactID'),
      CustomerID: userSession.CustomerID,
      CustomerRate: customerRate,
      CustomerRefNo: '',
      ExternalInterface: userSession.OnlineInterface,
      ExternalInterfaceType: userSession.OnlineInterfaceType,
      IncomingFunds: [] as any,
      LockedSide: fixedLockedSide,
      MarketRate: marketRate,
      OutgoingFunds: [],
      ProfitAmount: profit,
      RateQuoteID: rateQuoteID,
      SellAmount: buyAmount.toFixed(scaleByCurrency(buyCurrency)),
      SellCurrency: buyCurrency,
      Status: userSession.DealApproveRequired ? 'Pending for Approval' : 'Accepted',
      DealOrigin: 'Online Deal',
      DealType: 1,
      UpdateBuyAmountField: fixedLockedSide === 'Sell' ? true : false,
      UpdatedBy: localStorage.getItem('UserID'),
      ValueDate: valueDate,
      DealHeaderID: 0,//New Deal,
      SettlementDeliveryMethods: deliveryMethod && deliveryMethod.length > 0 ? deliveryMethod[0].translationlabel : ''
    };

    objDealHeader.IncomingFunds.push({
      Amount: sellAmount,
      BankAccountID: array[1],
      SettlementInstrument: array[2],
      SettlementDeliveryMethod: deliveryMethod && deliveryMethod.length > 0 ? deliveryMethod[0].translationlabel /*deliveryMethod[0].description*/ : ''
    });

    setObjDealHeaderAfterValidation(objDealHeader);

    let responseLimit = await validateCustomerAndContactLimits(objDealHeader.DealType, buyAmount, buyCurrency, objDealHeader.CustomerID, Number(objDealHeader.CustomerContactID));
    let alertTitle = '';
    let alertMessage = '';

    if (responseLimit.httpStatusCode === 200) {
      if (responseLimit.ValidationMessage === '') {
        callSaveDealHeader(objDealHeader);
      }
      else {
        if (responseLimit.LimitValidationStatus.toLowerCase() === 'warning') {
          setConfirmationModalTitle(getResource('Title.Warning'));
          setConfirmationModalMessage(responseLimit.ValidationMessage);
          setShowConfirmModalValidation(true);
        }
        else {
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = responseLimit.ValidationMessage;
          showModal(alertTitle, alertMessage);
          hideLoadingModal();
        }
      }
    }
    else {
      alertTitle = getResource('Js_MessageError_TitleError');
      alertMessage = getResource('Js_Message_TryAgainOrContactAdministrator');
      showModal(alertTitle, alertMessage);
      hideLoadingModal();
    }

    //callSaveDealHeader(objDealHeader);
  };

  const callSaveDealHeader = (objDealHeader: any) => {
    let array = settlementMethod.value.split(';');
    let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));

    saveDealHeader(objDealHeader).then((jsonDealResponse) => {
      //alert(jsonDealResponse.stringify(json));
      hideLoadingModal();
      var alertTitle = '', alertMessage = '';
      switch (Number(jsonDealResponse.httpStatusCode)) {
        case 200:
          objDealHeader.DealHeaderID = jsonDealResponse.DealHeaderID;
          objDealHeader.Status = jsonDealResponse.Status;
          setDealID(jsonDealResponse.DealHeaderID);
          var approvals = jsonDealResponse.ApprovalsJson;
          //var showApprovalsPopUp = false;
          alertTitle = getResource('Js_MessageError_TitleSuccess');
          alertMessage = getResource('Message_DealSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

          switch (jsonDealResponse.Status) {
            case 'Accepted':
              alertMessage = getResource('Message_OrderCommitted').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

              if (jsonDealResponse.HeldFundBalance != null && jsonDealResponse.HeldFundBalance > -1) {
                alertMessage = alertMessage + " " + getResource('YourCurrentBalance') + ": " + formatToCurrency(jsonDealResponse.HeldFundBalance.toString(), sellCurrency) + ' ' + sellCurrency;
                getListOfSettlementMethods(sellCurrency);
              }
              if (jsonDealResponse.LimitValidationMessage != undefined && jsonDealResponse.LimitValidationMessage != undefined) {
                alertMessage = alertMessage + " , " + jsonDealResponse.LimitValidationMessage;
              }
              //--this.sendDealEmail(jsonDealResponse.DealHeaderID);
              //this.sendConfirmationDirect(jsonDealResponse.DealHeaderID);
              props.onAcceptTrade(jsonDealResponse.DealHeaderID, buyCurrency, sellCurrency, buyAmount, valueDate, array[2], deliveryMethod, objDealHeader.CustomerRate, true);
              showModal(alertTitle, alertMessage);
              break;
            case 'Pending for Approval':
              if (approvals === undefined) {
                approvals = '';
              }
              if (approvals === '') {
                alertTitle = getResource('Js_MessageError_TitleError');
                alertMessage = getResource('Message_DealMissingApprovals');
                setShowApprovalsPopUp(false);
                props.onAcceptTrade(jsonDealResponse.DealHeaderID, buyCurrency, buyAmount, valueDate, array[2], deliveryMethod, objDealHeader.CustomerRate, true);
                showModal(alertTitle, alertMessage);
              } else {
                setApprovals(approvals);
                setCustomerRateDeal(objDealHeader.CustomerRate);
                setShowApprovalsPopUp(true);
              }
              break;
            case 'Pending':
              alertMessage = getResource('Message_OrderSavedAsPending').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

              let complianceRulesFound = jsonDealResponse != undefined &&
                ((jsonDealResponse.ComplianceSoftRules !== undefined && jsonDealResponse.ComplianceSoftRules !== null
                  && Array.isArray(jsonDealResponse.ComplianceSoftRules) && jsonDealResponse.ComplianceSoftRules.length > 0)
                  ||
                  (
                    jsonDealResponse.ComplianceRules !== undefined && jsonDealResponse.ComplianceRules !== null
                    && Array.isArray(jsonDealResponse.ComplianceRules) && jsonDealResponse.ComplianceRules.length > 0)
                )

              if (complianceRulesFound) {
                alertMessage = getResource('Message_DealCompliance');
              } else {
                alertMessage = alertMessage + jsonDealResponse.MessagePending;
              }

              showModal(alertTitle, alertMessage);
              onNewTradeClick()

              break;
            default:
              alertMessage = getResource('Message_OrderSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);
              props.onAcceptTrade(jsonDealResponse.DealHeaderID, buyCurrency, sellCurrency, buyAmount, valueDate, array[2], deliveryMethod, objDealHeader.CustomerRate, true);
              showModal(alertTitle, alertMessage);
              break;
          }
          break;
        default:
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = getResource('Message_DealError');

          if (jsonDealResponse.ValidationMessage !== undefined) {
            //validation message from API
            alertTitle = getResource('Js_MessageError_TitleValidation');
            alertMessage = jsonDealResponse.ValidationMessage;
          }
          else {
            if (jsonDealResponse.httpErrorMessage !== undefined) {
              //message from networking based on status code
              alertMessage = jsonDealResponse.httpErrorMessage;
            }
          }
          if (jsonDealResponse.Message == 'SentToInterface') {
            alertMessage = getResource('Message_DealErrorSentInterface');
          }

          props.onAcceptTrade(jsonDealResponse.DealHeaderID, buyCurrency, buyAmount, valueDate, array[2], deliveryMethod, objDealHeader.CustomerRate, false);
          showModal(alertTitle, alertMessage);
          break;
      }
    });
  }

  const closeSendEmailApproval = () => {
    setShowApprovalsPopUp(false);
    var alertTitle = '', alertMessage = '';
    let array = settlementMethod.value.split(';');
    let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));

    alertTitle = getResource('Js_MessageError_TitleSuccess');
    alertMessage = getResource('Message_CreditApproval');
    showModal(alertTitle, alertMessage);
    props.onAcceptTrade(dealID, buyCurrency, sellCurrency, buyAmount, valueDate, array[2], deliveryMethod, customerRateDeal, true);
  }

  const closeConfirmationModal = () => {
    setShowConfirmModal(false)
    setDealToConfirmAcceptance({})
    setConfirmationModalTitle('')
    onNewTradeClick()
  }

  const acceptDealInConfirmationModal = () => {
    setShowConfirmModal(false)
    console.log(dealToConfirmAcceptance)
    showLoadingModal();
    callSaveDealHeader(dealToConfirmAcceptance);
  }

  const yesLimitValidationAlert = () => {

    let objDealHeader = objDealHeaderAfterValidation;

    setShowConfirmModalValidation(false);
    callSaveDealHeader(objDealHeader);
  }

  const closeLimitValidationAlert = () => {
    hideLoadingModal();
    setShowConfirmModalValidation(false);
  }

  return (
    <React.Fragment>
      <div className="screen-container slide-r-l">
        <div className="row center-xs">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.BuyCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown name="buyCurrency" title={getResource('SelectCurrencyBuy')} list={(customerCurrencies as ICustomerCurrencies).payment} onChange={dropdownBuyChangeHandler} select={{ value: buyCurrency }} />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row between-xs">
                  <div className="col-xs-4" style={{ padding: 0 }}>
                    <span className="input-label" style={{ justifyContent: 'flex-start' }}>
                      {getResource('Form.Label.BuyAmount')}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    <RadioButton changed={radioChangeHandler} id="1" isSelected={lockedSide === 'sell'} label={getResource('Lock')} value="sell" />
                  </div>
                </div>
                <div className="row">
                  <Input className={validates ? '' : (lockedSide === 'buy' ? '' : formatValidInputClass(validInputClass(buyAmount.toString())))} type="number" disabled={lockedSide === 'buy'} value={buyAmount} onValueChange={onChangeBuyAmount} decimalScale={scaleBuyAmount} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SellCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="sellCurrency"
                    title={getResource('SelectCurrencySell')}
                    list={(customerCurrencies as ICustomerCurrencies).settlement}
                    onChange={(item: any, name: string) => {
                      dropdownSellChangeHandler(item, name);
                    }}
                    select={{ value: sellCurrency }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row between-xs">
                  <div className="col-xs-4" style={{ padding: 0 }}>
                    <span className="input-label" style={{ justifyContent: 'flex-start' }}>
                      {getResource('Form.Label.SellAmount')}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    <RadioButton changed={radioChangeHandler} id="2" isSelected={lockedSide === 'buy'} label={getResource('Lock')} value="buy" />
                  </div>
                </div>
                <div className="row">
                  <Input className={validates ? '' : (lockedSide === 'sell' ? '' : formatValidInputClass(validInputClass(sellAmount.toString())))} type="number" disabled={lockedSide === 'sell'} value={sellAmount} onValueChange={onChangeSellAmount} decimalScale={scaleSellAmount} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.ValueDate')}</span>
                </div>
                <div className="row">
                  <DatePicker key="app-datepicker-valuedate" className={validates ? '' : (formatValidInputClass(validInputClass(valueDate)))} onChange={onChangeValueDate} value={momentValueDate} disabledDate={disabledDate} />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SettlementMethod')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="settlementMethod"
                    title={getResource('SelectSettlementMethod')}
                    className={validates ? '' : (formatValidInputClass(validInputClass(settlementMethod.value)))}
                    settlementMode={true}
                    list={settlementMethods}
                    onChange={dropdownSettlementsChangeHandler}
                    //submit={true}
                    select={{ value: settlementMethod.value }}
                    disabled={sellCurrency === '' ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Button type="secondary" onClick={onNewTradeClick}>
                  {getResource('NewTrade')}
                </Button>
              </div>
              <div className="col-md-6">
                {OL_SpotGetQuote && OL_SpotAddNew && <QuoteBox
                  mode='modal'
                  settlementMethod={settlementMethod}
                  dealType={1}
                  buyCurrency={buyCurrency}
                  sellCurrency={sellCurrency}
                  customerID={Number.parseInt(customerID)}
                  contactID={Number.parseInt(contactID!)}
                  valueDate={valueDate}
                  forwardNearDate={valueDate}
                  amount={quoteAmount}
                  lockedSide={lockedSide}
                  totalFeesAmount={feeAmount}
                  amountCalc={amountCalc}
                  onNewTradeClick={onNewTradeClick}
                  onAcceptClick={onAcceptClick}
                  onCancelQuote={onCancelQuoteClick}
                  validateFields={validateFields}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="disclaimer-container">
        <div className="row center-xs">
          <div className="col-xs-12 row rate-disclaimer">
            <span>{getResource('AFEX_Message')}</span>
          </div>
          {(onlineInterface != null && onlineInterface.includes('AFEX - Pass Thru')) &&
            <span className="afex-disclaimer col-xs-12">
            {getResource('AFEX_Message_TC')}
          </span>
          }
        </div>
      </div>
      <SendApprovals show={showApprovalsPopUp} dealHeaderID={dealID} onClose={closeSendEmailApproval} approvals={custApprovals} />
      <ConfirmationModal visible={showConfirmModal} cancel={closeConfirmationModal} confirm={acceptDealInConfirmationModal} title={confirmationModalTitle} />
      <ConfirmationModal visible={showConfirmModalValidation} cancel={closeLimitValidationAlert} confirm={yesLimitValidationAlert} mainTitle={confirmationModalTitle} title={confirmationModalMessage} />
    </React.Fragment>
  );
};

export default Spot;
