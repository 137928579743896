import { LoadingOutlined } from '@ant-design/icons';
import { Breadcrumb, Modal } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useServerVersion from './api/hooks/useServerVersion';
import { validateSession } from './api/Networking';
import { getListOfDealsPendingDisburse } from './api/NetworkingDeals';
import { getParametersByName } from './api/NetworkingGlobalSystemParameters';
import { getRolesByCustomerAndContact } from './api/NetworkingRoles';
import { logout } from './api/NetworkingSecurity';
import { getListOfActionsByUser, sendForgotPasswordEmailOnline } from './api/NetworkingUsers';
import { AppProvider } from './App.context';
import './App.scss';
import Button from './components/inputs/Button';
import LoginScreen from './components/login/LoginScreen';
import ResetPassword from './components/login/ResetPassword';
import WorkSpace from './components/workspace/WorkSpace';
import { formatValidInputClass } from './helpers/FormatHelper';
import { getCurrentVersionNumber } from './helpers/PackageJsonHelper';
import { getResource } from './helpers/ResourcesHelper';
const REACT_APP_SESSION_TICK = window.REACT_APP_SESSION_TICK;

function App() {
  const localVersion = useMemo(() => getCurrentVersionNumber(), []);
  const [serverVersion] = useServerVersion();
  const [currentScreen, setCurrentScreen] = useState('login');
  const [sessionFunc, setSessionFunc] = useState<NodeJS.Timeout>();
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [showAfex, setShowAfex] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [listPendingDisburseDeals, setListPendingDisburseDeals] = useState([]);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);

  const [onlineRights, setOnlineRights] = useState('')

  const sessionInterval = useRef(null);

  const sessionTick = () => {
    validateSession().then((sessionResponse) => {
      if (sessionResponse !== undefined) {
        if (sessionResponse.httpStatusCode !== 200) {
          //@ts-ignore
          clearInterval(sessionInterval.current);
          //clearInterval(sessionFunc!);
          //localStorage.clear();
          setShowSessionModal(true);
          //setCurrentScreen('login');

        }
      } else {
        //@ts-ignore
        clearInterval(sessionInterval.current);
        //clearInterval(sessionFunc!);
        //localStorage.clear();
        //setCurrentScreen('login');
        setShowSessionModal(true);
      }
    });
  };

  const onCloseSessionModal = () => {
    setShowSessionModal(false);
    setCurrentScreen('login');
    localStorage.clear();
    window.location.reload();
  }

  const onFinishLoginScreen = useCallback(() => {
    setCurrentScreen('application');

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    if (userSession.OnlineLanguage != null) {
      localStorage.setItem('OnlineLanguage', userSession.OnlineLanguage);
    }
    getDealsPendingDisburse(userSession.CustomerID);
    //@ts-ignore
    sessionInterval.current = setInterval(sessionTick, REACT_APP_SESSION_TICK);
    //setSessionFunc(session);
  }, []);

  const onLogout = () => {
    localStorage.clear();
    setCurrentScreen('login');
    //@ts-ignore
    clearInterval(sessionInterval.current);
    setShowSessionModal(false);
    setShowAfex(false);
  };

  const onUpdateSession = async (newSession: any = null) => {
    if (newSession != null) {
      localStorage.setItem('UserSession', JSON.stringify(newSession));
      if (newSession.OnlineLanguage != null) {
        localStorage.setItem('OnlineLanguage', newSession.OnlineLanguage);
      }
      const rights = await getOnlineRights(newSession.CustomerID);
      localStorage.setItem('OnlineRights', JSON.stringify(rights));
      setOnlineRights(JSON.stringify(rights))
      const jsonParameters = await getParametersByName('AllowSameCurrencyForwards,SystemDate,CutoffTime,DefaultBuyCurrencyID,DefaultSellCurrencyID,WaitForManualRate,MaximumOpenForwardWindow');
      if (jsonParameters != null && jsonParameters.parameters != null) {
        for (var i = 0; i < jsonParameters.parameters.length; i++) {
          localStorage.setItem(jsonParameters.parameters[i].Name, jsonParameters.parameters[i].Value);
        }
      }
      getDealsPendingDisburse(newSession.CustomerID);
      // TODO: move hardcoded application Name to another file.
      const json = await getRolesByCustomerAndContact(newSession.CustomerID, newSession.ContactID, 'ForeignExchange');
      if (json != null) {
        if (json.length === 0) {
          await logout();
          localStorage.clear();
          onLogout();
          showModalMessage(getResource('Js_MessageError_TitleError'), 'Please contact us so we can activate your access to our online platform.');
        }
      }
      console.log('reload session')
    }
  };

  const getOnlineRights = async (customerID: number) => {
    const json = await getListOfActionsByUser(customerID);
    if (json != null && json.rights != null) {
      return json.rights;
    }
    // Same as r-online
    return {};
  };

  const getDealsPendingDisburse = (customerID: number) => {
    getListOfDealsPendingDisburse(customerID)
      .then((pendingDealsResponse) => {
        if (pendingDealsResponse != null && pendingDealsResponse.totalCount != null && pendingDealsResponse.totalCount > 0) {
          setListPendingDisburseDeals(pendingDealsResponse.deals);
        }
      });
  }
  const handleCancelModal = () => {
    setShowModal(false);
    setModalMessage('');
    setModalTitle('');
  };

  const showModalMessage = (title: string, message: string) => {
    setModalMessage(message);
    setModalTitle(title);
    setShowModal(true);
  };

  const onOkConfirmationAFEX = () => {
    setCurrentScreen('application');
    setShowAfex(false);
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    if (userSession.OnlineLanguage != null) {
      localStorage.setItem('OnlineLanguage', userSession.OnlineLanguage);
    }
    getDealsPendingDisburse(userSession.CustomerID);
    //var session = setInterval(sessionTick, 10000);
    //setSessionFunc(session);
    //@ts-ignore
    sessionInterval.current = setInterval(sessionTick, 10000);
  };

  const onCloseConfirmationAFEX = () => {
    localStorage.clear();
    setShowAfex(false);
  };

  const showAfexModal = () => {
    setShowAfex(true);
  };

  const showLoadingModal = () => {
    setShowLoading(true);
  }

  const hideLoadingModal = () => {
    setShowLoading(false);
  }

  const onSendForgotPasswod = () => {
    if (email == '') {
      setValidEmail(false);
    }
    else {
      localStorage.clear();
      setShowForgotPassword(false);
      showLoadingModal();
      sendForgotPasswordEmailOnline("ForeignExchange", email).then(
        (json) => {
          if (json !== undefined) {
            hideLoadingModal();
            if (json.httpStatusCode !== 200) {
              if (json.Message != '') {
                showModalMessage('Error', json.Message);
              }
              else {
                showModalMessage('Error', 'An error ocurred while sending the email');
              }
            } else {
              showModalMessage('Success', 'Email sent. Please check your inbox');
            }
          }
        }
      );
    }
  };

  const onCloseForgotPassword = () => {
    localStorage.clear();
    setShowForgotPassword(false);
  };

  const showForgotPasswordModal = () => {
    setShowForgotPassword(true);
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value != undefined && e.target.value != '') {
      setValidEmail(true);
    }
    else {
      setValidEmail(false);
    }
  }

  const onFinishResetPassword = () => {
    var url = window.location.origin;
    window.location.replace(url);
  }

  useEffect(() => {
    if (serverVersion !== '' && serverVersion !== localVersion) {
      window.location.reload();
    }
  }, [serverVersion, localVersion]);

  switch (currentScreen) {
    case 'login':
      if (window.location.pathname.indexOf("resetPassword") > -1) {
        const queryParams = new URLSearchParams(window.location.search);
        const key = queryParams.get("forgotpasswordkey");

        return (
          <AppProvider value={{ showModal: showModalMessage, showAfexModal: showAfexModal, showLoadingModal: showLoadingModal, hideLoadingModal: hideLoadingModal, showForgotPasswordModal: () => { } }}>
            <ResetPassword forgotpasswordkey={key} onFinishResetPassword={onFinishResetPassword} />
            {/* Modal */}
            <Modal
              title={
                <div className="breadcrumb-container">
                  <Breadcrumb separator="">
                    <Breadcrumb.Item >{modalTitle}</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              }
              centered
              visible={showModal}
              footer={null}
              onCancel={handleCancelModal}
              maskClosable={false}
              width={'25%'}
              zIndex={1021}
              closable={false}
            >
              {modalMessage}
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <Button id="confirmation-OK" type="primary" onClick={handleCancelModal}>
                    Ok
                  </Button>
                </div>
              </div>
            </Modal>

            <Modal
              //title={<h3 className="component-title">{modalTitle}</h3>}
              centered
              visible={showLoading}
              footer={null}
              //onCancel={handleCancelModal}
              maskClosable={false}
              width={'25%'}
              zIndex={1021}
              closable={false}
            >
              <div className="row center-xs">
                <div className="col-xs-3">
                  <LoadingOutlined style={{ fontSize: '75px' }} />
                </div>
              </div>
            </Modal>
          </AppProvider>
        );
      }
      else {
        return (
          <AppProvider value={{ showModal: showModalMessage, showAfexModal: showAfexModal, showLoadingModal: showLoadingModal, hideLoadingModal: hideLoadingModal, showForgotPasswordModal: showForgotPasswordModal }}>
          {!showAfex && <LoginScreen onFinishLogin={onFinishLoginScreen} />}
          {/* Modal */}
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{modalTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showModal}
            footer={null}
            onCancel={handleCancelModal}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            {modalMessage}
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <Button id="confirmation-OK" type="primary" onClick={handleCancelModal}>
                  Ok
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{'Important'}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showAfex}
            footer={null}
            onCancel={onCloseConfirmationAFEX}
            maskClosable={false}
            width={'50%'}
            zIndex={1021}
            closable={false}
          >
            <div>
              <div>
                <h4>{getResource('LoginAFEX')}</h4>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-OK" type="primary" onClick={onOkConfirmationAFEX}>
                      {getResource('Accept')}
                    </Button>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-cancel" type="primary" onClick={onCloseConfirmationAFEX}>
                      {getResource('Login.Logout')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
            {/* Forgot password */}
            <Modal
              title={
                <div className="breadcrumb-container">
                  <Breadcrumb separator="">
                    <Breadcrumb.Item >{getResource('ForgotPassword')}</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              }
              centered
              visible={showForgotPassword}
              footer={null}
              onCancel={onCloseForgotPassword}
              maskClosable={false}
              width={'35%'}
              zIndex={1021}
              closable={false}
            >
              <div>
                <div>
                  <h4>{getResource('ForgotPassword_Line1')}</h4>
                  <h4>{getResource('ForgotPassword_Line2')}</h4>
                </div>
                <div className="row">
                  <div className="row">
                    <span className="input-label">{getResource('Email')}</span>
                  </div>
                  <div className="row">
                    <input className={formatValidInputClass(validEmail)}
                      type="text" value={email} onChange={onChangeEmail}></input>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <div className="col">
                      <Button id="confirmation-forgot" type="primary" onClick={onSendForgotPasswod}>
                        {getResource('Button.Send')}
                      </Button>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="col">
                      <Button id="cancel-forgot" type="primary" onClick={onCloseForgotPassword}>
                        {getResource('Button.Cancel')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
        </AppProvider>
      );
      }
    case 'application':
      return (
        <AppProvider value={{ showModal: showModalMessage, showAfexModal: showAfexModal, showLoadingModal: showLoadingModal, hideLoadingModal: hideLoadingModal, showForgotPasswordModal: () => { } }}>
          <ApplicationScreen onLogout={onLogout} onUpdateSession={onUpdateSession} listPendingDisburseDeals={listPendingDisburseDeals} onlineRights={onlineRights} />
          {/* Modal */}
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{modalTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showModal}
            footer={null}
            onCancel={handleCancelModal}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            {modalMessage}
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <Button id="confirmation-OK" type="primary" onClick={handleCancelModal}>
                  Ok
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            //title={<h3 className="component-title">{modalTitle}</h3>}
            centered
            visible={showLoading}
            footer={null}
            //onCancel={handleCancelModal}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            <div className="row center-xs">
              <div className="col-xs-3">
                <LoadingOutlined style={{ fontSize: '75px' }} />
              </div>
            </div>
          </Modal>
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{'Important'}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showSessionModal}
            footer={null}
            onCancel={onCloseSessionModal}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            <div>
              <div>
                <h4>{getResource('Message_Session_Terminated')}</h4>
              </div>
              <div className="row around-xs">
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-session-OK" type="primary" onClick={onCloseSessionModal}>
                      OK
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </AppProvider>
      );
    default:
      return (
        <AppProvider value={{ showModal: showModalMessage, showAfexModal: showAfexModal, showLoadingModal: showLoadingModal, hideLoadingModal: hideLoadingModal, showForgotPasswordModal: () => { } }}>
          {!showAfex && <LoginScreen onFinishLogin={onFinishLoginScreen} />}
          {/* Modal */}
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{modalTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showModal}
            footer={null}
            onCancel={handleCancelModal}
            maskClosable={false}
            width={'25%'}
            zIndex={1021}
            closable={false}
          >
            {modalMessage}
          </Modal>
          <Modal
            title={
              <div className="breadcrumb-container">
                <Breadcrumb separator="">
                  <Breadcrumb.Item >{'Important'}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            }
            centered
            visible={showAfex}
            footer={null}
            onCancel={onCloseConfirmationAFEX}
            maskClosable={false}
            width={'50%'}
            zIndex={1021}
            closable={false}
          >
            <div>
              <div>
                <h4>{getResource('LoginAFEX')}</h4>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-OK" type="primary" onClick={() => onOkConfirmationAFEX()}>
                      {getResource('Accept')}
                    </Button>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button id="confirmation-afex-cancel" type="primary" onClick={() => onCloseConfirmationAFEX()}>
                      {getResource('Login.Logout')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </AppProvider>
      );
  }
}

const ApplicationScreen = (props: any) => {
  console.log(props)
  return (
    <div className="col prime-container">
      <WorkSpace onLogout={props.onLogout} onUpdateSession={props.onUpdateSession} listPendingDisburseDeals={props.listPendingDisburseDeals} onlineRights={props.onlineRights} />
    </div>
  );
};

export default App;
