import React, { useContext, useEffect, useState } from 'react';
import useCurrencies from '../../../api/hooks/useCurrencies';
import useCurrencyPairs from '../../../api/hooks/useCurrencyPairs';
import { usePrevious } from '../../../api/hooks/usePrevious';
import { getCurrenciesByCustomer } from '../../../api/NetworkingCurrencies';
import { getNextBusinessDate } from '../../../api/NetworkingHolidays';
import { getRate } from '../../../api/NetworkingRates';
import AppContext from '../../../App.context';
import { formatToCurrency, formatValidInputClass, scaleByCurrencyPair, validInputClass } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import RadioButton from '../../inputs/RadioButton';
import { RateWatch } from '../../models/RateWatch';

interface CurrenciesAndRatesProps {
  validationField: number;
  [x: string]: any;
  //props: any;
}

const moment = require('moment');

const CurrenciesAndRates: React.FC<CurrenciesAndRatesProps> = ({ validationField, ...props }) => {

  const [currencyPairs] = useCurrencyPairs();

  const { showModal } = useContext(AppContext);

  const [updating, setUpdating] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false)

  const [rateWatchId, setRateWatchId] = useState(0);
  const [rateWatch, setRateWatch] = useState<RateWatch>(props.rateWatch);
  const prevRateWatchIdSelected = usePrevious(rateWatchId);

  const [dealType, setDealType] = useState<string>('Spot');

  const [rateType, setRateType] = useState<string>('single');
  const [currencyBuyList] = useCurrencies();
  const [weWantTo, setWeWantTo] = useState<string>('buy');
  const [buyCurrency, setBuyCurrency] = useState<string | null>('');
  const [buyAmount, setBuyAmount] = useState<number | null>(null);
  const [sellCurrency, setSellCurrency] = useState<string | null>('');
  const [sellAmount, setSellAmount] = useState<number | null>(null);
  const [firstRate, setFirstRate] = useState<number | null>(0);
  const [firstAmount, setFirstAmount] = useState<number | null>(null);
  const [secondRate, setSecondRate] = useState<number | null>(0);
  const [secondAmount, setSecondAmount] = useState<number | null>(null);

  const [rateTerms, setRateTerms] = useState<string>();
  const [customerRate, setCustomerRate] = useState<number>();

  const [showCalculateRate, setShowCalculateRate] = useState(false);
  const [scaleBuyAmount, setScaleBuyAmount] = useState(2);
  const [scaleSellAmount, setScaleSellAmount] = useState(2);
  const [scaleDefaultAmount, setScaleDefaultAmount] = useState(2);
  const [settlementCurrencies, setSettlementCurrencies] = useState([]);

  const [validations, setValidations] = useState({
    rateType: true,
    buyCurrency: true,
    buyAmount: true,
    sellCurrency: true,
    sellAmount: true,
    firstRate: true,
    firstAmount: true,
    secondRate: true,
    secondAmount: true
  });

  const validationsFields = () => {
    var rateTypeValid = validInputClass(rateType);
    var buyCurrencyValid = true;
    var buyAmountValid = true;
    var sellCurrencyValid = true;
    var sellAmountValid = true;
    var firstRateValid = true;
    var secondRateValid = true;
    var firstAmountValid = true;
    var secondAmountValid = true;

    if (buyCurrency === '' || buyCurrency === null) { buyCurrencyValid = false; }
    if ((weWantTo === 'buy') && (buyAmount === null || buyAmount === 0)) { buyAmountValid = false; }
    if (sellCurrency === '' || sellCurrency === null) { sellCurrencyValid = false; }
    if ((weWantTo === 'sell') && (sellAmount === null || sellAmount === 0)) { sellAmountValid = false; }
    if (firstRate === null || firstRate === 0) { firstRateValid = false; }
    if (rateType === 'oco' && (secondRate === null || secondRate === 0)) { secondRateValid = false; }
    if (firstAmount === null || firstAmount === 0) { firstAmountValid = false; }
    if (rateType === 'oco' && (secondAmount === null || secondAmount === 0)) { secondAmountValid = false; }


    setValidations({
      rateType: rateTypeValid,
      buyCurrency: buyCurrencyValid,
      buyAmount: buyAmountValid,
      sellCurrency: sellCurrencyValid,
      sellAmount: sellAmountValid,
      firstRate: firstRateValid,
      firstAmount: firstAmountValid,
      secondRate: secondRateValid,
      secondAmount: secondAmountValid
    });

    var isValid = rateTypeValid && buyCurrencyValid && buyAmountValid && sellCurrencyValid && sellAmountValid && firstRateValid && firstAmountValid && secondRateValid && secondAmountValid;
    if (!isValid) { showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted')); }
    else {
      props.onUpdateStep(2);
    }

  }

  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem('UserSession')!);
    getCurrenciesByCustomer(userSession.CustomerID).then(
      (json) => {
        if (json !== undefined) {
          let jsonCurrencies = json.currencies;

          var settlementHelper = [] as any;
          if (jsonCurrencies !== undefined) {
            for (var i = 0; i < jsonCurrencies.length; i++) {
              var currencyElement = {
                value: jsonCurrencies[i].CurrencyID,
                label: jsonCurrencies[i].CurrencyIDName,
                numberOfDecimals: jsonCurrencies[i].NumberOfDecimals,
                currencyType: jsonCurrencies[i].CurrencyType
              };
              switch (jsonCurrencies[i].Type) {
                case 'Settlement':
                  settlementHelper.push(currencyElement);
                  break;
                default:
                  break;
              }
            }
          }
          setSettlementCurrencies(settlementHelper);
        }
      }
    );

    if (validationField != null && validationField > 0) {
      validationsFields();
    }
  }, [validationField])

  useEffect(() => {
    if (props.rateWatch != null && props.rateWatch.RateWatchID !== 0 && prevRateWatchIdSelected !== props.rateWatch.RateWatchID) {

      let rateWatchObj = props.rateWatch;

      setRateWatchId(rateWatchObj.RateWatchID);
      setBuyCurrency(rateWatchObj.BuyCurrency);
      setSellCurrency(rateWatchObj.SellCurrency);
      setScaleDefaultAmount(scaleByCurrencyPair(rateWatchObj.BuyCurrency, rateWatchObj.SellCurrency));
      setRateType(rateWatchObj.CheckSingleRate === true ? 'single' : 'oco');

      if (rateWatchObj.WeAction === "We Sell") {
        setWeWantTo('buy');
        setBuyAmount(Number(formatToCurrency(rateWatchObj.BuyAmount1!.toString(), rateWatchObj.BuyCurrency)));
        setFirstAmount(rateWatchObj.SellAmount1!);
      } else {
        setWeWantTo('sell');
        setSellAmount(rateWatchObj.SellAmount1!)
        setFirstAmount(rateWatchObj.BuyAmount1!);
      }
      setFirstRate(rateWatchObj.CustomerRate1!);

      if (rateWatchObj.CheckSingleRate === false) {
        setSecondRate(rateWatchObj.CustomerRate2!);
      }
      getRates();
    } else {
      setScaleDefaultAmount(scaleByCurrencyPair('', ''));
    }
  }, [updating, props.rateWatch]);

  /*
  useEffect(() => {
    getRates();

    calculateAmountsForRate1();
    calculateAmountsForRate2();

  }, [buyCurrency, sellCurrency]);
  */

  useEffect(() => {
    let rateWatchObj = rateWatch;
    if (rateWatchObj !== undefined && rateWatchObj !== null) {
      setFirstRate(rateWatchObj.CustomerRate1!);
      if (rateWatchObj.CheckSingleRate === false) {
        setSecondRate(rateWatchObj.CustomerRate2!);
      }

      getRates();


    }
  }, [buyCurrency, sellCurrency, weWantTo]);


  const radioButtonsActions = (event: any, type: string) => {
    let objSetters: { [key: string]: any } = {
      'rateType': () => {
        setRateType(event.target.value);
        setValidations({ ...validations, rateType: true });
        props.onUpdateRateWatch('CheckSingleRate', event.target.value === 'single' ? true : false);
      },
      'wewantto': () => {
        setWeWantTo(event.target.value);
        props.onUpdateRateWatch('WeAction', event.target.value === 'sell' ? 'We Buy' : 'We Sell');
      }
    }
    return (objSetters[type]())
  }

  const dropdownsActions = (item: any, type: string) => {
    let objSetters: { [key: string]: any } = {
      'buycurrency': () => {
        setBuyCurrency(item.value);
        setScaleDefaultAmount(scaleByCurrencyPair(item.value, sellCurrency != null ? sellCurrency : ''));
        setValidations({ ...validations, buyCurrency: true });
        setScaleBuyAmount(item.numberOfDecimals);
        props.onUpdateRateWatch('BuyCurrency', item.value);
      },
      'sellcurrency': () => {
        setSellCurrency(item.value);
        setScaleDefaultAmount(scaleByCurrencyPair(buyCurrency != null ? buyCurrency : '', item.value));
        setValidations({ ...validations, sellCurrency: true });
        setScaleSellAmount(item.numberOfDecimals);
        props.onUpdateRateWatch('SellCurrency', item.value);
      }
    }
    return (objSetters[type]())
  }

  const inputsActions = (value: number, type: string) => {
    switch (type) {
      case 'buyamount': {
        setBuyAmount(value);
        setValidations({ ...validations, buyAmount: true });
        break;
      }
      case 'sellamount': {
        setSellAmount(value);
        setValidations({ ...validations, sellAmount: true });
        break;
      }
      case 'firstrate': {
        setFirstRate(value);
        setValidations({ ...validations, firstRate: true });
        props.onUpdateRateWatch('CustomerRate1', value);
        break;
      }
      case 'secondrate': {
        setSecondRate(value);
        setValidations({ ...validations, secondRate: true });
        props.onUpdateRateWatch('CustomerRate2', value);
        break;
      }
      case 'firstamount': {
        setFirstAmount(value);
        setValidations({ ...validations, firstAmount: true });
        break;
      }
      case 'secondamount': {
        setSecondAmount(value);
        setValidations({ ...validations, secondAmount: true });
        break;
      }
    }
  }

  const getRates = () => {
    if ((buyCurrency !== '') && (sellCurrency !== '') && (buyAmount !== null && buyAmount !== 0) || (sellAmount !== null && sellAmount !== 0)) {
      let dealAmount = 0;
      let lockedSide = '';
      if (weWantTo === 'sell') {
        dealAmount = sellAmount === null ? 0 : sellAmount;
        lockedSide = 'Sell'
      } else {
        dealAmount = buyAmount === null ? 0 : buyAmount;
        lockedSide = 'Buy'
      }

      let spotDays = 0
      let valueDate = moment().format('YYYY/MM/DD');

      //@ts-ignore
      if (currencyPairs[sellCurrency + buyCurrency] !== undefined) {
        //@ts-ignore
        spotDays = currencyPairs[sellCurrency + buyCurrency].SpotDays;
      }

      getNextBusinessDate(valueDate, spotDays).then(x => {
        console.log(x)
        if (x !== undefined) {
          valueDate = x.nextDate
        }
        getRate(
          JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
          JSON.parse(localStorage.getItem('UserSession')!).ContactID,
          dealType === 'Spot' ? 1 : 4,
          valueDate,
          props.rateWatch === null ? '' : props.rateWatch.NearDate!,
          sellCurrency === null ? '' : sellCurrency,
          buyCurrency === null ? '' : buyCurrency,
          dealAmount,
          lockedSide,
          0,
          JSON.parse(localStorage.getItem('UserSession')!).OnlineInterface,
          JSON.parse(localStorage.getItem('UserSession')!).OnlineInterfaceType
        ).then(
          (response) => {
            if (response !== undefined && response !== null && response.httpStatusCode === 200 && response.CustomerRate > 0) {
              let rateTerms = response.RateTerms
              let customerRate = response.CustomerRate
              setRateTerms(rateTerms);
              setCustomerRate(customerRate);
              calculateAmounts(rateTerms, customerRate);
              setShowCalculateRate(true);
            }
          }
        )
      })
    }
  }

  const calculateAmounts = (rateTerms: string, customerRate: number) => {
    let dealAmount = 0
    let calculatedAmount = 0

    if (weWantTo === 'buy') {
      dealAmount = buyAmount === null ? 0 : buyAmount;
    } else {
      dealAmount = sellAmount === null ? 0 : sellAmount;
    }

    if (weWantTo === 'buy') {
      calculatedAmount = rateTerms === 'I' ? dealAmount / customerRate : dealAmount * customerRate
    } else {
      calculatedAmount = rateTerms === 'I' ? dealAmount * customerRate : dealAmount / customerRate
    }

    setBuyAmount(weWantTo === 'buy' ? dealAmount : calculatedAmount);
    setSellAmount(weWantTo === 'buy' ? calculatedAmount : dealAmount);

    props.onUpdateRateWatch('BuyAmount1', weWantTo === 'buy' ? dealAmount : calculatedAmount);
    props.onUpdateRateWatch('SellAmount1', weWantTo === 'buy' ? calculatedAmount : dealAmount);


    let firstRateAux1 = firstRate;
    let firstRateAux2 = firstRate;
    calculateAmountsForRate1();
    let firstRateAux3 = firstRate;

    if (rateType != 'single') {
      calculateAmountsForRate2();
    }
  }

  const calculateAmountsForRate1 = () => {
    let rate = firstRate === null ? 0 : firstRate;
    if ((rate === null ? 0 : rate > 0) && rateTerms !== null && rateTerms !== '') {
      let dealAmount = 0
      let calculatedAmount = 0

      if (weWantTo === 'buy') {
        dealAmount = buyAmount != null ? buyAmount : 0;
      } else {
        dealAmount = sellAmount != null ? sellAmount : 0;
      }

      if (weWantTo === 'buy') {
        calculatedAmount = rateTerms === 'I' ? dealAmount / rate : dealAmount * rate
      } else {
        calculatedAmount = rateTerms === 'I' ? dealAmount * rate : dealAmount / rate
      }

      setFirstAmount(calculatedAmount);

      props.onUpdateRateWatch('BuyAmount1', weWantTo === 'buy' ? dealAmount : calculatedAmount);
      props.onUpdateRateWatch('SellAmount1', weWantTo === 'buy' ? calculatedAmount : dealAmount);
    }
  }

  const calculateAmountsForRate2 = () => {
    let rate = secondRate === null ? 0 : secondRate;
    if (rate > 0 && rateTerms !== null && rateTerms !== '') {
      let dealAmount = 0
      let calculatedAmount = 0

      if (weWantTo === 'buy') {
        dealAmount = buyAmount === null ? 0 : buyAmount;
      } else {
        dealAmount = sellAmount === null ? 0 : sellAmount;
      }

      if (weWantTo === 'buy') {
        calculatedAmount = rateTerms === 'I' ? dealAmount / rate : dealAmount * rate;
      } else {
        calculatedAmount = rateTerms === 'I' ? dealAmount * rate : dealAmount / rate;
      }

      setSecondAmount(calculatedAmount);
      props.onUpdateRateWatch('BuyAmount2', weWantTo === 'buy' ? dealAmount : calculatedAmount);
      props.onUpdateRateWatch('SellAmount2', weWantTo === 'buy' ? calculatedAmount : dealAmount);
    }
  }

  return (
    <div className="screen-container slide-r-l">
      <div className="row center-xs">
        <div className="col-md-7">
        {/*We want to Buy*/}
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className="row">
                <span className="input-label">{getResource('Label.WeWantBuy')}</span>
            </div>
            <div className="row">
              <Dropdown
                name="buyCurrency"
                  title={getResource('SelectCurrencyBuy')}
                  list={settlementCurrencies}
                onChange={(item: any) => { dropdownsActions(item, 'buycurrency') }}
                select={{ value: buyCurrency }}
                className={formatValidInputClass(validations.buyCurrency)}
              /*submit        = {saving}
              errorMessage  = {"Buy Currency required!"}*/
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="row">
                <span className="input-label">{getResource('Amount')}</span>
            </div>
            <div className="row">
              <Input
                className={formatValidInputClass(validations.buyAmount)}
                type="number"
                  onValueChange={(value: number) => {
                    inputsActions(value, 'buyamount');
                }}
                value={buyAmount === null ? '' : buyAmount}
                onBlur={() => { getRates() }}
                  disabled={weWantTo === 'sell'}
                  decimalScale={scaleBuyAmount}
              /*submit={weWantTo === 'buy' && saving}
              errorMessage={"Amount required!"}*/
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="padding-top-35"></div>
            <div className="row">
              <RadioButton
                changed={(event: any) => { radioButtonsActions(event, "wewantto") }}
                id="radiobutton-lock-buy"
                isSelected={weWantTo === 'buy'}
                  label={getResource('Form.Label.LockBuySide')}
                value="buy"
              />
            </div>
          </div>
        </div>
        {/*We want to Sell*/}
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className="row">
                <span className="input-label">{getResource('Label.WeWantSell')}</span>
            </div>
            <div className="row">
              <Dropdown
                className={formatValidInputClass(validations.sellCurrency)}
                name="sellCurrency"
                  title={getResource('SelectCurrencySell')}
                  list={settlementCurrencies}
                onChange={(item: any) => { dropdownsActions(item, 'sellcurrency') }}
                select={{ value: sellCurrency }}
              /*submit        = {saving}
              errorMessage  = {"Sell Currency required!"}*/
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="row">
                <span className="input-label">{getResource('Amount')}</span>
            </div>
            <div className="row">
              <Input
                type="number"
                  onValueChange={(value: number) => { inputsActions(value, 'sellamount') }}
                value={sellAmount === null ? '' : sellAmount}
                onBlur={() => { getRates() }}
                  disabled={weWantTo === 'buy'}
                className={formatValidInputClass(validations.sellAmount)}
                  decimalScale={scaleSellAmount}
              /*submit={weWantTo === 'sell' && saving}
              errorMessage={"Amount required!"}*/
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <div className="padding-top-35"></div>
            <div className="row">
              <RadioButton
                changed={(event: any) => { radioButtonsActions(event, "wewantto") }}
                id="radiobutton-lock-sell"
                isSelected={weWantTo === 'sell'}
                  label={getResource('Form.Label.LockSellSide')}
                value="sell"
              />
            </div>
          </div>
        </div>
        {/*The current rate*/}
          {showCalculateRate && <div className="row">
          <div className="col-md-12 col-xs-12" style={{ border: '1px solid rgb(0 0 0 / 10%)', padding: 10 }}>
              <label>{getResource('Js.Message.CurrentRateAmountCurrency')} {customerRate} {getResource('For')} {weWantTo === 'sell' ? formatToCurrency(sellAmount !== null ? sellAmount.toString() : "0", sellCurrency?.toString()) : formatToCurrency(buyAmount !== null ? buyAmount.toString() : "0", buyCurrency?.toString())} {weWantTo === 'sell' ? sellCurrency : buyCurrency}</label>
          </div>
        </div>}
        {/*Rate Type*/}
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="row">
                <span className="input-label">{getResource('Table_TH_RateType')}</span>
            </div>
            <div className="row">
              <div className="col-md-3 col-xs-12 padding-top-5">
                <RadioButton
                  changed={(event: any) => { radioButtonsActions(event, "rateType") }}
                  id='radiobutton-single'
                    label={getResource('SingleRate')}
                  value="single"
                  isSelected={rateType === 'single'}
                />
              </div>
              <div className="col-md-5 col-xs-12 padding-top-5">
                <RadioButton
                  changed={(event: any) => { radioButtonsActions(event, "rateType") }}
                  id='radiobutton-oco'
                    label={getResource('OCO')}
                  value="oco"
                  isSelected={rateType === 'oco'}
                />
              </div>
            </div>
          </div>
        </div>
        {/*At First Rate*/}
        <div className="row">


          {/*First Rate - Amount*/}
          <div className="row">
            <div className="col-md-6 col-xs-6">
                <div className="row">
                  <span className="input-label">{getResource('AtThisRate')}</span>
                </div>
              <div className="row">
                <Input
                  className={formatValidInputClass(validations.firstRate)}
                  type="number"
                    onValueChange={(value: number) => {
                      inputsActions(value, 'firstrate');
                  }}
                  value={firstRate === null ? '' : firstRate}
                  onBlur={() => calculateAmountsForRate1()}
                    decimalScale={scaleDefaultAmount}
                /*submit        = {saving}
                errorMessage  = {"Rate is required!"}*/
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
                <div className="row">
                  <span className="input-label">{getResource('Equivalent') + " " + (weWantTo == 'buy' ? sellCurrency : buyCurrency)}</span>
                </div>
              <div className="row">
                <Input
                  type="number"
                  disabled
                    onValueChange={(value: number) => { inputsActions(value, 'firstamount') }}
                  value={firstAmount === null ? '' : firstAmount}
                  className={formatValidInputClass(validations.firstAmount)}
                    decimalScale={scaleSellAmount}
                />
              </div>
            </div>
          </div>
          {/*Second Rate - Amount*/}
          {rateType === 'oco' && <div className="row">
            <div className="col-md-6 col-xs-6">
              <div className="row">
                <Input
                  className={formatValidInputClass(validations.secondRate)}
                  type="number"
                    onValueChange={(value: number) => { inputsActions(value, 'secondrate') }}
                  value={secondRate === null ? '' : secondRate}
                  onBlur={() => calculateAmountsForRate2()}
                    decimalScale={scaleDefaultAmount}
                /*
                submit        = {saving}
                errorMessage  = {"Rate is required!"}
                */
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="row">
                <Input
                  type="number"
                  disabled
                    onValueChange={(value: number) => { inputsActions(value, 'secondamount') }}
                  value={secondAmount === null ? '' : secondAmount}
                  className={formatValidInputClass(validations.secondAmount)}
                    decimalScale={scaleSellAmount}
                />
              </div>
            </div>
          </div>}

          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrenciesAndRates